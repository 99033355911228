import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import {
  useContextBridge,
  OrbitControls,
  Html,
  useProgress,
} from "@react-three/drei";
import styled from "styled-components";
import PlaneModel from "../components/PlaneModel";

interface ColorProps {
  coverColor: string;
  textColor: string;
  urlTexture: string;
  urlBackTexture?: string;
}

type ThemeContext = { colors: { cover: string; text: string } };
const ThemeContext = React.createContext<ThemeContext>(null!);

function Loader() {
  const { progress } = useProgress();
  return <Html center>{progress} % loaded</Html>;
}

const PlaneScene: React.FC<ColorProps> = React.memo(
  ({ coverColor, textColor, urlTexture, urlBackTexture }) => {
    const SceneWrapper = React.memo(() => {
      const ContextBridge = useContextBridge(ThemeContext);
      const theme = React.useContext(ThemeContext);
      return (
        <>
          <Canvas
            mode="concurrent"
            //frameloop="demand"
            camera={{ zoom: 1.5, position: [20, 10, 30], fov: 45 }}
            dpr={[1, 2]}
            flat
            resize={{ scroll: false }}
          >
            <ambientLight color={0xf3f3f3} />
            <pointLight
              position={[0, 0, 200]}
              color={0xffffff}
              intensity={0.25}
              distance={1000}
              castShadow={true}
            />
            <directionalLight
              position={[1, -1, 1]}
              color={0xf7e7e7}
              intensity={0.25}
            />
            {/* create the bridge inside the Canvas and forward the context */}
            <ContextBridge>
              <Suspense fallback={<Loader />}>
                <PlaneModel
                  urlTexture={urlTexture}
                  urlBackTexture={urlBackTexture}
                  colorCover={theme.colors.cover}
                />
              </Suspense>
            </ContextBridge>
            <OrbitControls
              minPolarAngle={Math.PI / 2}
              maxPolarAngle={Math.PI / 2}
              enablePan={false}
              enableZoom={false}
            />
          </Canvas>
        </>
      );
    });

    return (
      <ThemeContext.Provider
        value={{ colors: { cover: coverColor, text: textColor } }}
      >
        <CanvasWrapper>
          <SceneWrapper />
        </CanvasWrapper>
      </ThemeContext.Provider>
    );
  }
);

const CanvasWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  position: relative;
  z-index: 0;
  * > canvas {
    touch-action: auto !important;
    pointer-events: none;
  }

  @media only screen and (min-width: 52rem) {
    position: absolute;
    width: 50vw;
    top: -25%;
    right: -25%;
  }
  @media only screen and (min-width: 80rem) {
    * > canvas {
      touch-action: auto !important;
      pointer-events: auto;
    }
  }
  @media only screen and (min-width: 120rem) {
    position: absolute;
    width: 50vw;
    top: -12vw;
  }
`;

export default PlaneScene;
