import React, { useRef } from "react";
import * as THREE from "three";
import { useThree, useFrame } from "@react-three/fiber";
import { useTexture, Plane } from "@react-three/drei";
import useMediaQuery from "../hooks/useMediaQuery";

interface IProps {
  group?: JSX.IntrinsicElements["group"];
  colorCover?: string | undefined;
  urlTexture?: string | undefined;
  urlBackTexture?: string | undefined;
  position?: THREE.Vector3 | undefined;
  rotation?: THREE.Euler | undefined;
  scale?: THREE.Vector3 | undefined;
  link?: JSX.Element | undefined;
}

export default function PlaneModel({...props}: IProps) {
  const group = useRef<THREE.Group>(null!);

  // Animate model
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.z = -0.1 - (1 + Math.sin(t / 1.5)) / 20;
    group.current.rotation.x = Math.cos(t / 4) / 8;
    group.current.rotation.y = Math.sin(t / 4) / 8;
    group.current.position.y = (1 + Math.sin(t / 1.5)) / 10;
  });

  const { viewport } = useThree();

  const texture = useTexture(props.urlTexture as string);
  const backTexture = useTexture(props.urlBackTexture as string);
  texture.flipY = true; 
  backTexture.flipY = true;

  const isDesktop = useMediaQuery('(min-width: 960px)');

  return (
    <>    
      <group ref={group} {...props} dispose={null} 
        scale={isDesktop ? [(viewport.width / 8), (viewport.width / 8), (viewport.width / 9.5)] : [(viewport.width / 7), (viewport.width / 7), (viewport.width / 8.5)]}
      >
        <Plane args={[2.5, 3.5]} position={[0, 0, 0.01]}>
          <shaderMaterial
            attach="material"
            uniforms={{
              uTexture: { value: texture },
              uRadius: { value: 0.05 },
              uAspect: { value: 2.5 / 3.5 }
            }}
            side={THREE.FrontSide}
            vertexShader={`
              varying vec2 vUv;
              void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
              }
            `}
            fragmentShader={`
              uniform sampler2D uTexture;
              uniform float uRadius;
              uniform float uAspect;
              varying vec2 vUv;

              float roundedRect(vec2 uv, vec2 size, float radius) {
                vec2 halfSize = size * 0.5;
                vec2 pos = (uv - vec2(0.5)) * size + halfSize;
                vec2 absPos = abs(pos - halfSize);
                vec2 inner = absPos - halfSize + vec2(radius);
                float maxInner = max(inner.x, inner.y);
                vec2 clampedInner = max(inner, 0.0);
                return length(clampedInner) - radius;
              }

              void main() {
                vec2 uv = vUv;
                vec4 texColor = texture2D(uTexture, uv);
                vec2 size = vec2(1.0, 1.0 / uAspect);
                float dist = roundedRect(uv, size, uRadius);
                if (dist > 0.0) {
                  discard;
                }
                gl_FragColor = texColor;
              }
            `}
          />
        </Plane>
        <Plane args={[2.5, 3.5]} rotation={[0, Math.PI, 0]} position={[0, 0, -0.01]}>
          <shaderMaterial
            attach="material"
            uniforms={{
              uTexture: { value: backTexture },
              uRadius: { value: 0.05 },
              uAspect: { value: 2.5 / 3.5 }
            }}
            side={THREE.FrontSide}
            vertexShader={`
              varying vec2 vUv;
              void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
              }
            `}
            fragmentShader={`
              uniform sampler2D uTexture;
              uniform float uRadius;
              uniform float uAspect;
              varying vec2 vUv;

              float roundedRect(vec2 uv, vec2 size, float radius) {
                vec2 halfSize = size * 0.5;
                vec2 pos = (uv - vec2(0.5)) * size + halfSize;
                vec2 absPos = abs(pos - halfSize);
                vec2 inner = absPos - halfSize + vec2(radius);
                float maxInner = max(inner.x, inner.y);
                vec2 clampedInner = max(inner, 0.0);
                return length(clampedInner) - radius;
              }

              void main() {
                vec2 uv = vUv;
                vec4 texColor = texture2D(uTexture, uv);
                vec2 size = vec2(1.0, 1.0 / uAspect);
                float dist = roundedRect(uv, size, uRadius);
                if (dist > 0.0) {
                  discard;
                }
                gl_FragColor = texColor;
              }
            `}
          />
        </Plane>
      </group>
    </>
  );
}
