/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Fragment, useEffect } from "react";
import { Box, Flex, Heading, Text } from "rebass";
import Hero from "../../../components/Hero";
import ProjectFadeInTitle from "../../../components/ProjectFadeInTitle";
import ProjectTitleListing from "../../../components/ProjectTitleListing";
import BackToTop from "../../../components/BackToTop";
import { StaticImage } from "gatsby-plugin-image";
import IntroImg from "../../../img/oberon/oberon-eb-app-intro.jpg";
import PlaneScene from "../../../components/PlaneScene";

import Layout from "../../../layouts/";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ThemeColorSetter from "../../../components/ThemeColorSetter";
import CompanyProjects from "../../../components/CompanyProjects";

function ProjectTest() {
  const context = useThemeUI();
  const { theme } = context;

  const projectBgColor = theme?.colors?.oberonProjectBgEasybroker as string;
  const projectTextColor = theme?.colors?.oberonProjectTextEasybroker as string;
  const themeColor = theme?.colors?.oberonProjectBgEasybroker as string;
  const themeColorBg = theme?.colors?.background as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);

  return (
    <Layout>
      <ThemeColorSetter color={themeColor} colorBg={themeColorBg} />
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        <Hero
          projectType={"Showcase"}
          title={"Easybroker"}
          titleRow={"App"}
          titleClassName={"uppercase"}
          subtitle={
            "Simplifying stock and ETF portfolio ownership for everyday people with no brokerage or trading knowledge, enabling them to invest with the same opportunities and possibilities as the professionals.  "
          }
          productType={"Investment App, React Native, Product Design"}
          projectBg={projectBgColor}
          projectText={projectTextColor}
          year={"2022 (4 months)"}
          role={<Fragment>Product Design</Fragment>}
          company={"OBERON"}
          roleDesc={
            <Fragment>
              I led the efforts to design a new investment app from scratch,
              utilizing design thinking to overcome the challenges of
              unfamiliarity with the investment industry, all while delivering
              within deadlines.
            </Fragment>
          }
          disciplines={
            <Fragment>Branding &mdash; Design &mdash; Development</Fragment>
          }
          challenge={
            <Fragment>
              Having limited knowledge about the investment industry and working
              under a very strict deadline forced me to tackle UX and UI
              simultaneously. This was only possible by staying grounded and
              prioritizing tasks. The development team supported the effort by
              focusing on creating a minimal lovable product (MLP) and
              leveraging native solutions to avoid customization. We reserved
              additional custom features for after the launch.
              {/* <Heading as="h3" variant="catHeading" my={[4]}>
                Personal note
              </Heading>
              <Text as="p" fontWeight={"bold"}>
                Why add this project to my portfolio?
              </Text>
              This project showcases my versatility and demonstrates how best
              design practices can be applied to any project, regardless of the
              platform or framework. One of the reasons I was chosen to work on
              this app was precisely due to my lack of knowledge in the
              investment industry, allowing for a mutual learning experience.
              This highlights my ability to tackle unfamiliar industries using
              design thinking methodology, confidently navigating the unknown to
              deliver solutions. */}
            </Fragment>
          }
          img={IntroImg}
          component={
            <PlaneScene
              coverColor={"#f0c844"}
              textColor={"silver"}
              urlTexture={"/models/textures/oberon-cover-easybroker.jpg"}
              urlBackTexture={"/models/textures/oberon-cover-easybroker.jpg"}
            />
          }
        />

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          id="project-content"
        >
          <Box width={[1 / 1, 12 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"From pitch"}
              word2={"to"}
              word2ClassName={"outline"}
              className={"uppercase"}
              word3={"polished"}
              word3ClassName={"outline"}
              word4={"product"}
            />
          </Box>
          <Box variant="containerTxt">
            <Heading as="h3" variant="catHeading">
              The Ask
            </Heading>
            <Text as="p" variant="paragraphXL" mb={[4]}>
              The client was looking for a solution to design and implement a
              completely new investment app that was easy-to-use and deviated
              from traditional models. While convenience was a key proposition
              to attract growth, it was essential to maintain depth and
              credibility, ensuring the brand remained financially trustworthy.
            </Text>
            <Heading as="h3" variant="catHeading">
              Overarching business goals
            </Heading>
            <Text as="p" mb={[4, 5, 6]}>
              Oberon aimed to demonstrate its design process and solutions,
              highlight its UX/UI capabilities, stay within budget and on
              schedule, and introduce design systems as a method for scaling
              brands and platforms.
            </Text>
            <Heading as="h2" variant="bigTitleXS">
              Our approach
            </Heading>
            <Text as="p" mb={[4, 5]}>
              We designed an intuitive investment app for the client, featuring
              a familiar basket system similar to eCommerce websites. Our
              approach focused on understanding user expectations, emotional
              responses, and primary usage paths, alongside the client’s
              business objectives, KPIs, and time constraints. This
              comprehensive perspective enabled us to design a step-by-step
              product experience that meets both user and business needs.
            </Text>
            <Flex flexWrap="wrap" mb={[4, 5, 6]}>
              <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                <Heading as="h3" variant="catHeading">
                  #1 Branding direction
                </Heading>
                <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                  Set up a design system for consistent brand application
                </Text>
              </Box>
              <Box width={[1 / 1, 1 / 1, 1 / 3]} mb={[2, 3, 4]}>
                <Heading as="h3" variant="catHeading">
                  #2 Simplified user flows
                </Heading>
                <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                  Clear and engaging flows to facilitate user retention
                </Text>
              </Box>
              <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                <Heading as="h3" variant="catHeading">
                  #3 Features
                </Heading>
                <Text as="p">
                  Added familiar flows to support user understanding of trading
                </Text>
              </Box>
            </Flex>
            <Heading as="h2" variant="bigTitleXS">
              Project methodoldoy
            </Heading>
            <Text as="p" mb={[4, 5, 6]}>
              <strong>
                Workshops → Prototyping → User testing → Design iteration
              </strong>
              <br />
              <br />
              For this project, we utilized <strong>design sprints</strong> and
              hosted <strong>workshops</strong> to identify user pain points and
              devise solutions. Initially, we designed and prototyped the main
              flows for buying, selling, and discovering within the app,
              gathering user feedback through testing. Once branding was
              finalized, we refined the prototype with the new design,
              addressing all details while development was underway.
            </Text>
          </Box>
        </Flex>

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          id="project-content"
        >
          <Box width={[1 / 1, 12 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Challenges"}
              className={"uppercase"}
              word3={"we overcame"}
              word3ClassName={"outline"}
            />
          </Box>
          <Box variant="containerTxt">
            <Heading as="h2" variant="bigTitleXS"></Heading>
            <Heading as="h3" variant="catHeading">
              1. Branding
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Implementing a strong identity across all screens with a Design
              System
            </Heading>
            <Text as="p" mb={[4, 5]}>
              In today’s market, standing out requires a strong brand identity.
              Users experience brands in multiple ways, and the UX/UI and visual
              elements of a brand’s app communicate the company’s values and
              character. While the branding direction was provided by an
              external agency, our team at Oberon was responsible for making it
              work seamlessly across all screens. We played a significant role
              in applying the new branding, including establishing a
              comprehensive design system to document every decision.
              Understanding that a design system needs clear instructions, we
              documented not only reusable components but also user flows. This
              ensured that everyone involved understood the system’s purpose and
              how to use it effectively.
            </Text>
            <Box width={[1 / 1]}>
              <StaticImage
                src="../../../img/oberon/oberon-eb-styleguide.png"
                alt="EB - Styleguide"
              />
            </Box>
            <Text as="span" variant="caption" mb={[4, 5, 6]}>
              Component <strong>Navigation bar</strong> usage
            </Text>
            <Heading as="h3" variant="catHeading">
              2. Trading flows for beginners
            </Heading>
            <Text as="p" mb={[4, 5]}>
              <Heading as="h2" variant="bigTitleXS">
                eCommerce shopping basket for stocks and ETFs
              </Heading>
              Our most notable solution, which is atypical within the industry,
              was to implement an eCommerce flow featuring a shopping basket
              which customers can use to trade(buy/sell) stocks or ETFs. User
              interviews confirmed that this clear and simple approach, avoiding
              the overwhelming data typical of traditional stock apps, was
              successful.
            </Text>
            
            <Box width={[1 / 1]}>
              <StaticImage
                src="../../../img/oberon/oberon-eb-devices-bottom-navigation.png"
                alt="EB - Bottom navigation screens"
              />
            </Box>
            <Text as="span" variant="caption" mb={[4, 5, 6]}>
              Screens from the <strong>Search &amp; Discovery and Basket</strong> flows showcasing the bottom navigation states
            </Text>
            
            <Heading as="h3" variant="catHeading">
              3. Intuitive Search & Discovery
            </Heading>
            <Heading as="h2" variant="bigTitleXS">
              Simplifying investment with contextual navigation and resources
              for beginners
            </Heading>
            <Text as="p" mb={[4, 5]}>
              While identifying the new app’s primary user scenarios and
              examining its functionality, we noticed that users struggled with
              understanding concepts such as ETFs and navigating too many
              options. To address this, we improved navigation by introducing
              contextual navigation "pills" within the Search & Discovery flow
              to narrow down searches. These new pages gradually presented
              information to users, making the investment process transparent
              and easy to understand. Resources such as investor helpdesk
              support, how-to videos, and beginner investing guides were
              incorporated within the app. This engaged users and made the
              overall user journey simpler and more intuitive.
            </Text>
            
            <Box width={[1 / 1]}>
              <StaticImage
                src="../../../img/oberon/oberon-eb-devices-ETFs.png"
                alt="EB - Search & Discovery APP screens"
              />
            </Box>
            <Text as="span" variant="caption" mb={[4, 5, 6]}>
              Screens from the <strong>Search &amp; Discovery</strong> flow
            </Text>
            
            <Heading as="h2" variant="bigTitleXS">
              Personal note
            </Heading>

            <Text as="p" variant="paragraphXL">
              Why add this project to my portfolio?
            </Text>
            <Text as="p" mb={[4, 5]}>
              This project showcases my versatility and{" "}
              <strong>
                demonstrates how best design practices can be applied to any
                project
              </strong>
              , regardless of the platform or framework. One of the reasons I
              was chosen to work on this app was precisely due to my lack of
              knowledge in the investment industry, allowing for a mutual
              learning experience. This highlights my ability to tackle
              unfamiliar industries using design thinking methodology,
              confidently navigating the unknown to deliver solutions.
            </Text>
          </Box>
      
        </Flex>
        {/* More from this company */}
        <CompanyProjects title="OBERON" />
        <ProjectTitleListing />  
        <BackToTop />
        <Footer />
      </main>
    </Layout>
  );
}
export default ProjectTest;
